<script>
import { cloudImageBaseUrl } from '@/CONF.js'
import Clipboard from "clipboard";

export default {
    name: 'RechargeForm',
    data() {
        return {
            cloudImageBaseUrl,
        }
    },
    props: [
        'currentSelectPay',
        'currentType',
        'sheet',
    ],
    inject:[
    ],
    methods: {
        async copyText() {
            let clipboard = new Clipboard(".copy");
            clipboard.on("success", function(e) {
                if (clipboard) {
                    clipboard.destroy();
                }
            });
            app.Mint.Toast(getWord('clipboard_done'));
        },
        bankSelectInput(event){
            event.target.blur();
        },
    },
    mounted() {
    },
    computed:{
    },
}
</script>

<template>
    <div class="form" v-if="_TEMPLATE==='template-3' && currentSelectPay.item">
        <div class="fieldset" v-if="currentType.id===1 || currentType.id===6">
            <h6></h6>
            <div>
                <label>{{ getWord('bank_name3') }}</label>
                <input type="text" disabled="true" v-model="currentSelectPay.item.bank_name" />
                <a class="copy" :data-clipboard-text="currentSelectPay.item.bank_name" @click="copyText()">{{ getWord('copy') }}</a>
            </div>
        </div>
        <div class="fieldset" v-if="currentType.id===6">
            <h6></h6>
            <div>
                <label>{{ getWord('bank_branch') }}</label>
                <input type="text" disabled="true" v-model="currentSelectPay.item.subbranch" />
                <a class="copy" :data-clipboard-text="currentSelectPay.item.subbranch" @click="copyText()">{{ getWord('copy') }}</a>
            </div>
        </div>
        <div class="fieldset" v-if="currentType.id===1 || currentType.id===6">
            <h6></h6>
            <div>
                <label>{{ getWord('username5') }}</label>
                <input type="text" disabled="true" v-model="currentSelectPay.item.payee" />
                <!-- <a class="copy" :data-clipboard-text="currentSelectPay.item.payee" @click="copyText()">复制</a> -->
            </div>
        </div>
        <div class="fieldset">
            <h6></h6>
            <div>
                <label>{{ getWord('account3') }}</label>
                <input type="text" disabled="true" v-model="currentSelectPay.item.account_num" />
                <a class="copy" :data-clipboard-text="currentSelectPay.item.account_num" @click="copyText()">{{ getWord('copy') }}</a>
            </div>
        </div>
        <div class="fieldset avatar" v-if="currentType.id==='3'||currentType.id==='2'">
            <h6></h6>
            <div>
                <label><img :src="currentSelectPay.item.pic?cloudImageBaseUrl+currentSelectPay.item.pic:'www.baidu.com'" v-if="currentSelectPay.item.type!==1" /></label>
                <input type="text" disabled="true" :value="getWord('copy_qrcode')" />
            </div>
        </div>
        <div class="fieldset" v-if="currentType.id===1">
            <h6>
                <i class="iconfont icon-credit"></i>
            </h6>
            <div class="select" @click="sheet.bankcard=true">
                <label>{{ getWord('bank_name3') }}</label>
                <input type="text" :placeholder="getWord('fill_bank2')" @focus="bankSelectInput($event)" :value="currentSelectPay.bankcardName" />
                <i class="iconfont icon-back"></i>
            </div>
        </div>
        <div class="fieldset" v-if="currentType.id===6">
            <h6>
                <i class="iconfont icon-credit"></i>
            </h6>
            <div>
                <label>{{ getWord('bank_name3') }}</label>
                <input type="text" :placeholder="getWord('416')" v-model="currentSelectPay.bankcardName" />
            </div>
        </div>
        <div class="fieldset">
            <h6>
                <img :src="require('@@/assets/'+_TEMPLATE+'/images/recharge/icons/money.png')" />
            </h6>
            <div>
                <label>{{ getWord(['recharge', 'amount_of_betting']) }}</label>
                <input type="number" :placeholder="getWord(['fill', 'amount_of_betting'])" v-model="currentSelectPay.amount" />
            </div>
        </div>
        <div class="fieldset">
            <h6>
                <img :src="require('@@/assets/'+_TEMPLATE+'/images/recharge/icons/id-card.png')" />  
            </h6>
            <div>
                <label>{{ getWord('username7') }}</label>
                <input type="text" :placeholder="getWord('fill_username3')" v-model="currentSelectPay.remitter" />
            </div>
        </div>
        <div class="fieldset" v-if="currentType.id===1">
            <h6>
                <i class="iconfont icon-recharge"></i>
            </h6>
            <div class="select" @click="sheet.remittanceMethod=true">
                <label>{{ getWord('transfer_address') }}</label>
                <input type="text" :placeholder="getWord('fill_transfer_channel')" @focus="bankSelectInput($event)" :value="currentSelectPay.remittanceMethod" />
                <i class="iconfont icon-back"></i>
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>
@import "@@/assets/style/_variables";
.form {

    &.template-3 {
        margin: 0 -.25rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        background-color: #eeeeee;

        .fieldset {
            display: flex;
            align-items: center;
            padding: 0;
            border:0;

            &.avatar {

                > div {
                    display: flex;

                    img {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
            }

            &:first-child {
                border-top:0;
            }

            &:last-child {

                > div {
                    border-bottom: 0;
                }
            }

            label {
                width: 1.8rem;
                font-size: .28rem;
                line-height: 1;
            }

            input {
                width: auto;
                font-size: .28rem;
                line-height: 2;
                padding: 0.05rem .25rem;
                box-sizing: border-box;
                flex-grow: 1;

                &:disabled {
                    background-color: transparent;
                    width: 3.3rem;
                }
            }

            > h6 {
                width: .4rem;
                padding: 0 .25rem;
                flex: 0 0 auto;
                text-align: center;

                .iconfont {
                    color: #EC2829;
                }

                img {
                    height: .3rem;
                    vertical-align: middle;
                }
            }

            > div {
                border-bottom: 1px solid #d0d0d0;
                padding: 0.1rem 0;
                position: relative;
                display: flex;
                align-items: center;
                flex-grow: 1;
                padding-right: .1rem;

                &.standalone {
                    border-top: 1px solid #d0d0d0;

                    label {
                        color: #5F646E;
                    }
                }

                &.select {                                

                    .icon-back {
                        position: absolute;
                        top:50%;
                        transform: rotateZ(270deg) translateY(-.5rem) translateX(50%);
                        transform-origin: center;
                        opacity: .4;
                    }
                }
            }

            .copy {
                background-color: #EDB93F;
                display: inline-block;
                font-size: .28rem;
                color: #ffffff;
                text-align: center;
                white-space: nowrap;
                padding: .14rem .45rem;
                border-radius: 3px;
                position: absolute;
                right: .1rem;
            }
        }
    }

    button {

        &.submit {
            font-size: .3rem;
            display: block;
            background-color: $TEMPLATE2-theme-color;
            color: #fff;
            border: 0;
            width: 98%;
            margin: 0 auto;
            margin-top: .25rem;
            padding: .2rem 0;
        }
    }

    > input {
        width: 98%;
        border: 1px solid #cccccc;
        padding: .25rem;
        box-sizing: border-box;
        margin: .25rem 1%;

        &::placeholder {
           color: #cccccc;
        }
    }

    fieldset,
    .fieldset {
        border:0;
        border-bottom:1px solid #f5f5f9;
        font-size: .3rem;
        padding: .25rem 0;

        &:first-child {
            border-top:1px solid #f5f5f9;
        }

        label {
            width: 1.6rem;
            display: inline-block;
        }

        input {
            font-size: .3rem;
            border:0;
            width: 5rem;
        }
    }
}
</style>